import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from "yup";
import TitleRadioGroup from '../components/Controls/TitleRadioGroup';
import PaymentRadioGroup from '../components/Controls/PaymentRadioGroup';

const StylesRadio = () => {
    return (
        <Container maxWidth='sm' >
            <Formik
                initialValues={{
                    Title: '',
                    Payment: ''
                }}
                validationSchema={Yup.object({
                    Title: Yup.string().required("A title option is required"),
                    Payment: Yup.string().required("A payment option is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        console.log(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 40);
                }}
            >
                {({ isSubmitting }) => (
                    <Form noValidate>
                        <h2>Radio group</h2>
                        <TitleRadioGroup label="What's your title?" />
                        <PaymentRadioGroup
                            annually={{
                                label: "Annually",
                                pricePounds: "£330",
                                pricePence: ".99",
                                priceDescription: <>A one-off<br />annual payment</>,
                            }}
                            monthly={{
                                label: "Monthly",
                                pricePounds: "£33",
                                pricePence: ".99",
                                priceDescription: <>Per month for <br />12 months</>,
                            }}
                        />
                        <Button type="submit" disabled={isSubmitting} fullWidth>
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container >
    );
};

export default StylesRadio;
