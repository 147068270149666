import { Box, createStyles, FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles, Radio, RadioGroup, Theme } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        labelRoot: {
            color: theme.palette.text.primary,
            fontFamily: "Spartan MB SemiBold",
            fontSize: "1rem",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            '& :focused': {
                color: '#000'
            }
        },
        radioLabel: {
            margin: '6px 12px 4px'
        },
        radioButtonBorderUnselected: {
            transition: theme.transitions.create(
                `all`,
                { duration: 200 }
            ),
            borderColor: '#e5e5e5',
        },
        radioButtonBorderSelected: {
            transition: theme.transitions.create(
                `all`,
                { duration: 200 }
            ),
            borderColor: theme.palette.secondary.main,
        },
        radioButtonBorderBase: {
            borderWidth: '2px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            margin: theme.spacing(1, 1),
            borderStyle: 'solid',
            '& span ': {
                fontFamily: 'Spartan MB',
                fontSize: '0.9375rem',
            },
        },
        legend: {
            margin: '20px 0 0 0',
        },
    }),
);

interface RadioButtonGroupProps {
    value?: string;
    error?: string;
    touched?: boolean;
    label: string;
    className?: string;
    children: JSX.Element[];
};

const RadioButtonGroup = ({
    error,
    touched,
    label,
    children
}: RadioButtonGroupProps) => {
    const classes = useStyles();

    return (
        <FormControl component="fieldset" data-testid="client-title" className={classes.legend}>
            <FormLabel
                classes={{
                    root: classes.labelRoot
                }}
                component="legend"
            >
                {label}
            </FormLabel>
            <RadioGroup aria-label="position" name="position" row>
                {children}
            </RadioGroup>
            {touched && error && <FormHelperText error={true}>{error}</FormHelperText>}
        </FormControl>
    );
};

interface TitleRadioGroupProps {
    label: string;
};

const TitleRadioGroup = ({ label }: TitleRadioGroupProps) => {
    const classes = useStyles();
    const { values, errors, touched } = useFormikContext();
    const isChecked = (radioValue: string, storedValue: string) => radioValue === storedValue;

    return (
        <RadioButtonGroup
            label={label}
            value={values.Title}
            error={errors && (errors as any).Title}
            touched={touched && (touched! as any).Title}
        >
            <Field name="Title">
                {({ field }: FieldProps) => (
                    <Box className={`${classes.radioButtonBorderBase} ${isChecked("Mr", field.value) ? classes.radioButtonBorderSelected : classes.radioButtonBorderUnselected}`} boxShadow={isChecked("Mr", field.value) ? 5 : 0}>
                        <FormControlLabel
                            {...field}
                            className={classes.radioLabel}
                            name={field.name}
                            checked={isChecked("Mr", field.value)}
                            onChange={() => field.onChange(field.name)("Mr")}
                            control={<Radio
                                color="primary"
                                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />}
                            label="Mr"
                            labelPlacement="top"
                            data-testid="radio-button"
                        />
                    </Box>
                )}
            </Field>
            <Field name="Title">
                {({ field }: FieldProps) => (
                    <Box className={`${classes.radioButtonBorderBase} ${isChecked("Mrs", field.value) ? classes.radioButtonBorderSelected : classes.radioButtonBorderUnselected}`} boxShadow={isChecked("Mrs", field.value) ? 5 : 0}>
                        <FormControlLabel
                            {...field}
                            className={classes.radioLabel}
                            name={field.name}
                            checked={isChecked("Mrs", field.value)}
                            onChange={() => field.onChange(field.name)("Mrs")}
                            control={<Radio
                                color="primary"
                                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />}
                            label="Mrs"
                            labelPlacement="top"
                            data-testid="radio-button"
                        />
                    </Box>
                )}
            </Field>
            <Field name="Title">
                {({ field }: FieldProps) => (
                    <Box className={`${classes.radioButtonBorderBase} ${isChecked("Miss", field.value) ? classes.radioButtonBorderSelected : classes.radioButtonBorderUnselected}`} boxShadow={isChecked("Miss", field.value) ? 5 : 0}>
                        <FormControlLabel
                            {...field}
                            className={classes.radioLabel}
                            name={field.name}
                            checked={isChecked("Miss", field.value)}
                            onChange={() => field.onChange(field.name)("Miss")}
                            control={<Radio
                                color="primary"
                                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />}
                            label="Miss"
                            labelPlacement="top"
                            data-testid="radio-button"
                        />
                    </Box>
                )}
            </Field>
            <Field name="Title">
                {({ field }: FieldProps) => (
                    <Box className={`${classes.radioButtonBorderBase} ${isChecked("Ms", field.value) ? classes.radioButtonBorderSelected : classes.radioButtonBorderUnselected}`} boxShadow={isChecked("Ms", field.value) ? 5 : 0}>
                        <FormControlLabel
                            {...field}
                            className={classes.radioLabel}
                            name={field.name}
                            checked={isChecked("Ms", field.value)}
                            onChange={() => field.onChange(field.name)("Ms")}
                            control={<Radio
                                color="primary"
                                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />}
                            label="Ms"
                            labelPlacement="top"
                            data-testid="radio-button"
                        />
                    </Box>
                )}
            </Field>
        </RadioButtonGroup>
    );
};

export default TitleRadioGroup;