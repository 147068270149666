import {
    Box,
    createStyles,
    FormControl,
    FormControlLabel,
    FormHelperText,
    makeStyles,
    Radio,
    RadioGroup,
    Theme,
    Typography,
} from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        radioLabel: {
            margin: theme.spacing(2, 1, 1),
        },
        priceContainer: {
            display: 'block',
        },
        priceLabel: {
            fontSize: '1rem',
            fontFamily: 'Spartan MB Bold',
            color: theme.palette.text.secondary,
        },
        price: {
            fontFamily: 'Spartan MB ExtraBold',
            fontSize: '1.875rem',
            [theme.breakpoints.down(374)]: {
                fontSize: '1.5rem',
            },
        },
        pricePence: {
            fontFamily: 'Spartan MB ExtraBold',
            fontSize: '1rem',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.875rem',
            },
        },
        priceDescription: {
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
            display: 'inline-block',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.75rem',
            },
        },
        radioButtonBorderUnselected: {
            transition: theme.transitions.create(`all`, { duration: 200 }),
            borderColor: '#e5e5e5',
        },
        radioButtonBorderSelected: {
            transition: theme.transitions.create(`all`, { duration: 200 }),
            borderColor: theme.palette.secondary.main,
        },
        radioButtonBorderBase: {
            borderWidth: '2px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            margin: theme.spacing(1, 1),
            borderStyle: 'solid',
            textAlign: 'center',
            flex: 1,
            maxWidth: '186px',
        },
    }),
);

interface RadioButtonGroupProps {
    value?: string;
    error?: string;
    touched?: boolean;
    label?: string;
    className?: string;
    children: JSX.Element[];
}

const RadioButtonGroup = ({ error, touched, children }: RadioButtonGroupProps) => {
    const classes = useStyles();
    return (
        <FormControl component="fieldset" data-testid="payment-options" className={classes.root}>
            <RadioGroup aria-label="position" name="position" row>
                {children}
            </RadioGroup>
            {touched && error && <FormHelperText error={true}>{error}</FormHelperText>}
        </FormControl>
    );
};

interface RadioButtonProps {
    label: string;
    pricePounds: string;
    pricePence: string;
    priceDescription: JSX.Element;
}

const RadioButton = ({
    field,
    label,
    pricePounds,
    pricePence,
    priceDescription,
}: FieldProps & RadioButtonProps) => {
    const classes = useStyles();
    const isChecked = (radioValue: string, storedValue: string) => radioValue === storedValue;
    return (
        <Box
            className={`${classes.radioButtonBorderBase} ${
                isChecked(label, field.value)
                    ? classes.radioButtonBorderSelected
                    : classes.radioButtonBorderUnselected
            }`}
            boxShadow={isChecked(label, field.value) ? 5 : 0}
        >
            <FormControlLabel
                {...field}
                className={classes.radioLabel}
                name={field.name}
                checked={isChecked(label, field.value)}
                onChange={() => field.onChange(field.name)(label)}
                control={
                    <Radio
                        color="primary"
                        icon={<RadioButtonUncheckedIcon fontSize="small" />}
                        checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                    />
                }
                label={
                    <>
                        <Typography className={classes.priceLabel} component="span">
                            {label}
                        </Typography>
                        <Box component="span" className={classes.priceContainer}>
                            <Typography className={classes.price} variant="h6" component="span">
                                {pricePounds}
                            </Typography>
                            <Typography className={classes.pricePence} component="span">
                                {pricePence}
                            </Typography>
                        </Box>
                        <Typography component="span" className={classes.priceDescription}>
                            {priceDescription}
                        </Typography>
                    </>
                }
                labelPlacement="top"
                data-testid="radio-button"
            />
        </Box>
    );
};

interface Props {
    annually: RadioButtonProps;
    monthly: RadioButtonProps;
}

const PaymentRadioGroup = ({ annually, monthly }: Props) => {
    const { values, errors, touched } = useFormikContext();
    return (
        <RadioButtonGroup
            value={values.Payment}
            error={errors && (errors as any).Payment}
            touched={touched && (touched! as any).Payment}
        >
            <Field name="Payment">
                {({ field, form, meta }: FieldProps) => (
                    <RadioButton
                        label={monthly.label}
                        pricePounds={monthly.pricePounds}
                        pricePence={monthly.pricePence}
                        priceDescription={monthly.priceDescription}
                        field={field}
                        form={form}
                        meta={meta}
                    />
                )}
            </Field>
            <Field name="Payment">
                {({ field, form, meta }: FieldProps) => (
                    <RadioButton
                        label={annually.label}
                        pricePounds={annually.pricePounds}
                        pricePence={annually.pricePence}
                        priceDescription={annually.priceDescription}
                        field={field}
                        form={form}
                        meta={meta}
                    />
                )}
            </Field>
        </RadioButtonGroup>
    );
};

export default PaymentRadioGroup;
